import { Text, Title, Anchor } from '@mantine/core';
import { animate, motion, useInView } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import stackDetail from '../images/stack-detail.jpg';
import papermaking from '../images/papermaking.jpg';
import paperExcellence from '../images/paper-excellence-location.svg';
import weaving from '../images/weaving.jpg';
import water from '../images/water-treatment.jpg';
import workers1 from '../images/workers-1.jpg';
import workers1_2x from '../images/workers-1-2x.jpg';
import workers2 from '../images/workers-2.jpg';
import workers2_2x from '../images/workers-2-2x.jpg';
import workers3 from '../images/workers-3.jpg';
import workers3_2x from '../images/workers-3-2x.jpg';
import workers4 from '../images/workers-4.jpg';
import workers4_2x from '../images/workers-4-2x.jpg';
import workers5 from '../images/workers-5.jpg';
import workers5_2x from '../images/workers-5-2x.jpg';
import bubbles from '../videos/bubbles.mp4';
import doc from '../assets/橙氧中英文产品手册V3-精简版.pdf';
import './cases.less';
import SEO from "../components/seo";

const images = [
  { thumbnail: workers1, raw: workers1_2x, className: 'img1', },
  { thumbnail: workers2, raw: workers2_2x, className: 'img2', },
  { thumbnail: workers3, raw: workers3_2x, className: 'img3', },
  { thumbnail: workers4, raw: workers4_2x, className: 'img4', },
  { thumbnail: workers5, raw: workers5_2x, className: 'img5', },
];

function Counter({ from, to }: { from: number, to: number}) {
  const nodeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        if (node) {
          node.textContent = value.toFixed(0);
        }
      }
    });

    return () => controls.stop();
  }, [from, to]);

  return <span ref={nodeRef} />;
}

function Cases() {
  const numberRef = useRef<HTMLSpanElement>(null);
  const hasBeenInView = useInView(numberRef, { once: true });
  const [previewImage, setPreviewImage] = useState<string>();

  return (<div className='cases'>
    <section className="bg">
      <video src={bubbles} autoPlay loop muted></video>
      <div className="text-tag">Hydrogen peroxide is an ideal green oxidant.</div>
    </section>
    <section className="application">
      <Title className='title' order={1}>Hydrogen peroxide is an ideal green oxidant.</Title>
      <Title className='sub-title' order={2}>Phase Two Chemicals’ on-site hydrogen peroxide production equipment integrates with:</Title>
      <div className='industry papermaking'>
        <img src={papermaking} />
        <div className='industry-mask'>
          <div className='text-container'>
            <Title className='name' order={3}>Pulp & Paper Bleaching.</Title>
            <Text className='description'>Almost half of the world’s consumption of hydrogen peroxide is in the pulp and paper industry for bleaching. Most mills are located in remote locations, which typically have low electricity costs, making Phase Two Chemicals’ technology is a great candidate for pulp and paper customers. In Kraft pulp bleaching, our technology can be directly used in peroxide reinforced alkaline extraction (Eop or Ep), peroxide bleaching stage (P) or at the end of a bleaching sequence for preventing brightness reversion. In mechanical pulp mills, it can be used in most refining and bleaching operations. Besides the large cost reduction, our technology also improves the safety and reliability of supply because hydrogen peroxide is generated on-site at low concentration for direct use.</Text>
          </div>
        </div>
      </div>
      <div className='industry weaving'>
        <img src={weaving} />
        <div className='industry-mask'>
          <div className='text-container'>
            <Title className='name' order={3}>Textile Bleaching.</Title>
            <Text className='description'>Natural cotton and other fiber materials contain impurities, resulting in off-white and yellow hues. Hydrogen peroxide is the bleaching agent of choice for removing color so that the product textiles can be dyed, because it can remove coloring agents without damaging the fibers, unlike harsher chlorine bleaches. Phase Two Chemicals’ technology can be used to reduce the cost and improve security of supply for textile bleaching operations.</Text>
          </div>
        </div>
      </div>
      <div className='industry water'>
        <img src={water} />
        <div className='industry-mask'>
          <div className='text-container'>
            <Title className='name' order={3}>Water Treatment.</Title>
            <Text className='description'>Hydrogen peroxide has utility in a multitude of drinking water and wastewater treatment applications. It can be used as an oxidant for taste and odor removal in drinking water treatment plants or to control sulfide odors in wastewater treatment plants. It can also be used as a pre-oxidant to aid in coagulation as well as reduce undesirable disinfection byproduct precursors. Hydrogen peroxide can also be combined with ultraviolet light or ozone to promote advanced oxidation processes and can sometimes be used to replace or reduce the use of chlorine or chlorine-based oxidizers, reducing undesirable disinfection byproducts. On-site generated hydrogen peroxide can reduce the cost of expensive bulk deliveries, as well as eliminate handling of barrels of concentrated hydrogen peroxide, decreasing costs and improving safety.</Text>
          </div>
        </div>
      </div>
    </section>
    <section className="emission-reduction">
      <div className='title-container'>
        <Title className='title' order={1}>The electrochemical solution using Phase Two Chemicals’ technology will reduce the emission of carbon dioxide by 2.492 tons per ton of hydrogen peroxide produced when utilizing low-carbon electricity and by 1.25 tons per ton of hydrogen peroxide produced when utilizing grid-average electricity.</Title>
        <Title className='sub-title' order={2}>Our goal is to reduce the hydrogen peroxide manufacturing industry’s total global emissions by 25,000,000 tons annually.</Title>
      </div>
      <motion.span
        className='number'
        ref={numberRef}
      >
        {hasBeenInView && <Counter from={0} to={25000000} />}
      </motion.span>
      <img src={stackDetail}/>
    </section>
    <section className="papermaking-customer">
      <div className="title-container">
        <Title className='title' order={1}>We work closely with pulp and paper giants</Title>
        <Title className='sub-title' order={2}>By consuming only water, electricity, and oxygen, our green technology disrupts the conventional fossil-fuel-based hydrogen peroxide industry.</Title>
      </div>
      <img className='papermakingMap' src={paperExcellence} />
      <div className='gallary'>
        {images.map(({ className, thumbnail, raw}, index) => (
          <img key={index} className={className} src={thumbnail} onClick={() => setPreviewImage(raw)} />
        ))}
      </div>
      {previewImage && <div className="preview-mask" onClick={() => setPreviewImage(undefined)}>
        <img src={previewImage} />
      </div>}
    </section>
  </div>);
}

export default Cases;

export const Head = () => (<SEO />)
